<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Admins
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-row justify="end" class="px-5">
                    <v-dialog v-model="dialog" persistent max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addadmin" ref="addadmin">
                          <v-card-title>
                            <span class="headline">Add Admin</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="firstname"
                                    label="First Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="lastname"
                                    label="Last Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="username"
                                    label="UserName"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="password"
                                    label="Password"
                                    :rules="Rules"
                                    required
                                  ></v-text-field>
                                </v-col> -->

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="phone"
                                    label="Phone Number"
                                    :rules="phoneRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="emailRules"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" class="pt-8">
                                  <v-select
                                    dense
                                    color="#000000"
                                    label="Role"
                                    v-model="role"
                                    :items="roles"
                                    item-color="#000000"
                                    hide-details
                                  >
                                    <template v-slot:item="{ item }">
                                      <span class="text-left">
                                        {{ item }}
                                      </span>
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addadmin"
                              @click="adminadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="admin"
                  :search="search"
                  hide-default-footer
                  :items-per-page="count"
                  class="elevation-1"
                   @click:row="admininfo"
                >
                  <template v-slot:[`item.firstname`]="{ item }">
                    <span>{{ item.firstname }}</span>
                    <span> {{ item.lastname }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="admininfo(item)">
                      mdi-eye</v-icon
                    >
                    <v-icon small class="mr-2" @click="edit(item._id)">
                      mdi-pencil
                    </v-icon>
                    <!-- <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon> -->
                  </template>
                </v-data-table>
                <div class="pt-2" v-if="pages > 1">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      showsnackbar: false,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
      dialog: false,
      search: "",
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      phone: "",
      email: "",
      msg:"",
      roles: ["Admin", "SubAdmin"],
      role: "",
      addadmin: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 15 || "Min 15 characters",
      },
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
      headers: [
        { text: "Name", value: "firstname" },
        { text: "Username", value: "username" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Role", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      Rules: [
        (v) => !!v || "Password is Required",
        (v) => (v && v.length <= 4) || "Password must be less than 5",
        (v) => (v && v.length >= 4) || "Password must be grater than 4",
        (v) => /^\d{4}$/.test(v) || "Password must be number",
      ],
      admin: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  methods: {
    initialize() {
      this.admin = [];
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/allAdmins",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.admin = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    admininfo(aid) {
      this.$router.push({ name: "adminDetails", params: { adminid: aid._id } });
    },
    edit(aid) {
      this.$router.push({ name: "editAdmin", params: { adminid: aid } });
    },
    adminadd() {
      var data = {};
      data["firstname"] = this.firstname;
      data["lastname"] = this.lastname;
      data["username"] = this.username;
      data["password"] = this.password;
      data["phone"] = this.phone;
      data["email"] = this.email;
      data["role"] = this.role;
      axios({
        url: "/user/adminregister",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>